// src/components/ChatInterface.jsx
import React from 'react';
import { useTheme } from '../context/ThemeContext';

const TypingIndicator = () => (
    <div className="flex space-x-2 p-3 bg-white/5 rounded-xl">
      <div className="w-2 h-2 rounded-full bg-blue-500 animate-bounce" 
        style={{ animationDelay: '0ms' }} />
      <div className="w-2 h-2 rounded-full bg-blue-500 animate-bounce" 
        style={{ animationDelay: '200ms' }} />
      <div className="w-2 h-2 rounded-full bg-blue-500 animate-bounce" 
        style={{ animationDelay: '400ms' }} />
    </div>
  );

const ChatInterface = ({ mousePosition }) => {
    const { isDark } = useTheme();
    const { x, y } = mousePosition;

    return (
        <div 
        className="relative w-full max-w-lg mx-auto transform transition-all duration-300 ease-out"
        style={{
            transform: `perspective(1000px) rotateY(${x}deg) rotateX(${y}deg)`
        }}
        >
        <div className={`bg-white/10 dark:bg-gray-900/10 backdrop-blur-xl rounded-2xl p-6 border 
            border-white/20 dark:border-gray-700/20 shadow-2xl
            ${isDark ? 'text-white' : 'text-gray-900'}`}>
            {/* Chat Header */}
            <div className="flex items-center justify-between mb-6">
            <div className="flex space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-400"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                <div className="w-3 h-3 rounded-full bg-green-400"></div>
            </div>
            <div className="text-white/60 text-sm">AequitasAI Chat</div>
            </div>

            {/* Chat Messages */}
            <div className="space-y-4">
            <Message 
                type="ai" 
                text="How can I help you with legal document drafting today?"
            />
            <Message 
                type="user" 
                text="I need to create an NDA for my startup"
            />
            <Message 
                type="ai" 
                text="I'll help you create a customized NDA. First, what type of NDA do you need? (One-way or mutual?)"
            />
            <Message 
                type="user" 
                text="I need a mutual NDA"
            />
            <Message 
                type="ai" 
                text="Great choice! I'll draft a mutual NDA. Let me ask you a few questions to customize it perfectly for your needs..."
            />
            </div>

            {/* Chat Input */}
            <div className="mt-6">
            <div className="flex items-center bg-white/5 rounded-xl p-3 border border-white/10">
                <input 
                type="text" 
                placeholder="Type your message..." 
                className="bg-transparent flex-1 outline-none text-white/60 placeholder-white/40"
                disabled
                />
                <button className="ml-2 p-2 rounded-lg bg-blue-500 text-white">
                Send
                </button>
            </div>
            </div>
        </div>

        {/* Decorative glow */}
        <div className="absolute -z-10 inset-0 blur-2xl opacity-30 bg-gradient-to-r from-blue-500 to-purple-500"></div>
        </div>
    );
};

// Message component
const Message = ({ type, text }) => {
    const { isDark } = useTheme();
    const isAI = type === 'ai';
    
    return (
      <div className={`flex items-start space-x-2 ${!isAI && 'justify-end'}`}>
        {isAI && (
          <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center">
            🤖
          </div>
        )}
        <div className={`${
          isAI 
            ? isDark ? 'bg-blue-500/10' : 'bg-blue-100' 
            : isDark ? 'bg-blue-600/10' : 'bg-blue-50'
        } rounded-2xl p-3 max-w-[80%]`}>
          <p className={isDark ? 'text-white/90' : 'text-gray-800'}>
            {text}
          </p>
        </div>
        {!isAI && (
          <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
            👤
          </div>
        )}
      </div>
    );
  };

export default ChatInterface;