// src/components/Footer.js
import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { NavLink } from 'react-router-dom';
import { FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import logo from '../../assets/logo/logo.png';

const Footer = () => {
  const { isDark } = useTheme();

  return (
    <footer className={`${isDark ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-700'} py-12`}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <NavLink to="/" className="flex items-center space-x-1 group mb-4">
              <img
                src={logo}
                className="h-10"
                alt="AequitasAI Logo"
              />
              <span className="self-center text-2xl font-semibold">
                AequitasAI
              </span>
            </NavLink>
            <p className={`${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Transforming legal work with AI-powered solutions.
            </p>
          </div>

          {/* Navigation Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Navigation</h3>
            <ul className="space-y-2">
              <li>
                <NavLink to="/" className="hover:text-blue-500">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about" className="hover:text-blue-500">About</NavLink>
              </li>
              <li>
                <NavLink to="/features" className="hover:text-blue-500">Features</NavLink>
              </li>
              <li>
                <NavLink to="/pricing" className="hover:text-blue-500">Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="hover:text-blue-500">Contact</NavLink>
              </li>
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <NavLink to="/" className="hover:text-blue-500">Privacy Policy</NavLink>
              </li>
              <li>
                <NavLink to="/" className="hover:text-blue-500">Terms of Service</NavLink>
              </li>
              <li>
                <NavLink to="/" className="hover:text-blue-500">Disclaimer</NavLink>
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-blue-500">
                <FaLinkedinIn className="w-6 h-6" />
              </a>
              <a href="#" className="hover:text-blue-500">
                <FaInstagram className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-8">
          <p className="text-center text-sm">
            © {new Date().getFullYear()} AequitasAI. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
