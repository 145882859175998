// src/components/Navbar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ThemeToggle from './ThemeToggle';
import logo from '../../assets/logo/logo.png';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Add scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled 
        ? 'bg-white/75 dark:bg-gray-900/75 backdrop-blur-lg shadow-lg' 
        : 'bg-transparent dark:bg-transparent'
    }`}>
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <NavLink to="/" className="flex items-center space-x-1 group">
          <div className="relative">
            <div className="absolute inset-0 bg-blue-500 rounded-full 
              blur-lg opacity-0 group-hover:opacity-25 transition-opacity duration-300">
            </div>
            <img src={logo}
              className="h-14 relative z-10" 
              alt="AequitasAI Logo" 
            />
          </div>
          <span className="self-center text-2xl font-semibold text-gray-900 dark:text-white">
            AequitasAI
          </span>
        </NavLink>
        <div className="flex md:order-2 items-center space-x-6">
          <ThemeToggle />  
          <NavLink to="/">
            <button className="get-trial-btn px-5 py-2.5 rounded-lg 
              bg-gradient-to-r from-blue-600 to-indigo-600 
              text-white font-medium 
              hover:from-blue-700 hover:to-indigo-700 
              transition-all duration-300
              hover:shadow-lg hover:shadow-blue-500/25
              transform hover:scale-105"
            >
              Get Free Trial
            </button>
          </NavLink>

          {/* Mobile menu button */}
          <button 
            onClick={() => setIsOpen(!isOpen)} 
            type="button" 
            className="inline-flex items-center p-2 w-10 h-10 justify-center 
              text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 
              focus:outline-none focus:ring-2 focus:ring-gray-200 ml-6"
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 transition-all duration-300 ease-in-out ${isOpen ? 'block' : 'hidden'} md:block`}>
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0 md:border-0 border border-gray-100 dark:border-gray-700 md:border-0 transition-all duration-300">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => `
                  relative nav-link block py-2 px-3 md:px-0
                  transition-all duration-300
                  ${isActive 
                    ? 'text-blue-500 dark:text-blue-400 font-medium' 
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'}
                  group
                `}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => `
                  relative nav-link block py-2 px-3 md:px-0
                  transition-all duration-300
                  ${isActive 
                    ? 'text-blue-500 dark:text-blue-400 font-medium' 
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'}
                  group
                `}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => `
                  relative nav-link block py-2 px-3 md:px-0
                  transition-all duration-300
                  ${isActive 
                    ? 'text-blue-500 dark:text-blue-400 font-medium' 
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'}
                  group
                `}  
              >
                Features
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => `
                  relative nav-link block py-2 px-3 md:px-0
                  transition-all duration-300
                  ${isActive 
                    ? 'text-blue-500 dark:text-blue-400 font-medium' 
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'}
                  group
                `}
              >
                Pricing
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => `
                  relative nav-link block py-2 px-3 md:px-0
                  transition-all duration-300
                  ${isActive 
                    ? 'text-blue-500 dark:text-blue-400 font-medium' 
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'}
                  group
                `}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
