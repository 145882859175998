// src/components/LandingPage.js
import React, { useState, useEffect } from 'react';
import FeaturesSection from './features/FeaturesSection';
import TestimonialsSection from './TestimonialsSection';
import Footer from '../layout/Footer';
import ChatInterface from './ChatInterface'; // We'll create this component
import { useTheme } from '../context/ThemeContext';
import HowItWorks from './HowItWorks';
import SupportedDocuments from './SupportedDocuments';
import FAQ from './FAQ';
import CTASection from './CTASection';

const LandingPage = () => {
  const { isDark } = useTheme();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { innerWidth, innerHeight } = window;
      const x = (clientX - innerWidth / 2) / innerWidth;
      const y = (clientY - innerHeight / 2) / innerHeight;
      setMousePosition({ x: x * 20, y: y * 20 });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <>
      <div className={`min-h-screen relative overflow-hidden
        ${isDark 
          ? 'bg-gradient-to-b from-gray-900 to-gray-800' 
          : 'bg-gradient-to-b from-gray-50 to-white'}`}
      >
        {/* Background Effects - Adjusted for light/dark mode */}
        <div className="absolute inset-0">
          <div className={`absolute top-20 left-40 w-72 h-72 rounded-full 
            mix-blend-multiply filter blur-xl animate-blob
            ${isDark ? 'bg-blue-500 opacity-10' : 'bg-blue-300 opacity-20'}`}
          ></div>
          <div className={`absolute top-40 right-40 w-72 h-72 rounded-full 
            mix-blend-multiply filter blur-xl animate-blob animation-delay-2000
            ${isDark ? 'bg-purple-500 opacity-10' : 'bg-purple-300 opacity-20'}`}
          ></div>
          <div className={`absolute -bottom-20 left-20 w-72 h-72 rounded-full 
            mix-blend-multiply filter blur-xl animate-blob animation-delay-4000
            ${isDark ? 'bg-indigo-500 opacity-10' : 'bg-indigo-300 opacity-20'}`}
          ></div>
        </div>

        {/* Hero Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-20 relative">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Left Column - Text Content */}
            <div className="space-y-8">
              <div className="inline-block">
                <span className={`inline-flex items-center px-3 py-1 rounded-full 
                  text-sm font-medium
                  ${isDark 
                    ? 'bg-blue-500/10 text-blue-400 ring-1 ring-blue-400/20' 
                    : 'bg-blue-100 text-blue-600 ring-1 ring-blue-500/20'}`}>
                  Beta Access Available
                </span>
              </div>
              
              <h1 className="text-5xl md:text-6xl font-bold">
                <span className={`block bg-gradient-to-r 
                  ${isDark 
                    ? 'from-blue-400 to-indigo-400' 
                    : 'from-blue-600 to-indigo-600'} 
                  bg-clip-text text-transparent`}>
                  Transform Legal Work
                </span>
                <span className={`block mt-2
                  ${isDark ? 'text-white' : 'text-gray-900'}`}>
                  with AI Assistant
                </span>
              </h1>

              <p className={`text-xl max-w-xl
                ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                Create precise legal documents in minutes. Our AI understands context,
                ensures compliance, and helps you work smarter.
              </p>

              <div className="flex flex-col sm:flex-row gap-4">
                <button className="px-8 py-4 bg-blue-600 rounded-xl 
                  hover:bg-blue-700 transition-all duration-300 transform 
                  hover:scale-105 text-white font-medium
                  shadow-lg shadow-blue-500/25 hover:shadow-blue-500/40">
                  Start Free Trial
                </button>
                <button className={`px-8 py-4 rounded-xl border
                  transition-all duration-300 font-medium 
                  flex items-center justify-center gap-2
                  ${isDark 
                    ? 'bg-white/5 border-white/10 hover:bg-white/10 text-white' 
                    : 'bg-gray-100 border-gray-200 hover:bg-gray-200 text-gray-700'}`}>
                  <span>Watch Demo</span>
                  <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </button>
              </div>
            </div>

            {/* Right Column - Chat Interface */}
            <div className="relative">
              <ChatInterface mousePosition={mousePosition} />
            </div>
          </div>
        </div>
      </div>

      <FeaturesSection />
      <HowItWorks />
      <SupportedDocuments />
      <FAQ />
      <CTASection />
      <Footer />
    </>
  );
};

export default LandingPage;