import React from 'react';
import { motion } from 'framer-motion';

const DocumentLine = ({ index, isDark, type = "text" }) => {
    const width = type === "text" ? 100 - (index * 15) : type === "heading" ? 60 : 40;
    
    return (
      <motion.div
        className={`flex items-center space-x-3 ${type === "heading" ? 'mb-6' : 'mb-3'}`}
      >
        {/* Label */}
        <motion.div
          className={`text-xs ${isDark ? 'text-gray-500' : 'text-gray-400'}`}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.1 }}
        >
          {type === "heading" ? "Title" : type === "subtitle" ? "Section" : `Line ${index + 1}`}
        </motion.div>
  
        {/* Line */}
        <motion.div
          className={`h-${type === "heading" ? "8" : type === "subtitle" ? "6" : "4"} rounded-md
            ${isDark ? 'bg-blue-500/10' : 'bg-blue-300/20'} flex-grow`}
          initial={{ width: '0%', opacity: 0 }}
          whileInView={{ width: `${width}%`, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: index * 0.1 }}
        />
      </motion.div>
    );
  };
  
  export const DocumentVisual = ({ isDark }) => {
    return (
      <div className="relative w-full h-[700px] perspective-1000">
        <motion.div 
          className={`absolute inset-0 rounded-2xl overflow-hidden backdrop-blur-xl
            border transition-colors duration-300 shadow-2xl
            ${isDark 
              ? 'border-white/10 bg-gray-900/50' 
              : 'border-gray-200 bg-white/50'}`}
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          {/* Document Editor Interface */}
          <div className="h-full flex flex-col">
            {/* Editor Toolbar */}
            <div className={`h-12 border-b ${isDark ? 'border-gray-800' : 'border-gray-200'}
              flex items-center px-4 justify-between`}>
              <div className="flex space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-400"/>
                <div className="w-3 h-3 rounded-full bg-yellow-400"/>
                <div className="w-3 h-3 rounded-full bg-green-400"/>
              </div>
              <div className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                Generating Legal Document...
              </div>
              <motion.div 
                className={`h-2 w-2 rounded-full ${isDark ? 'bg-blue-500' : 'bg-blue-600'}`}
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 1, repeat: Infinity }}
              />
            </div>
  
            {/* Document Content */}
            <div className="flex-1 p-8">
              <div className="max-w-3xl mx-auto">
                {/* Document Header */}
                <DocumentLine index={0} isDark={isDark} type="heading" />
                <DocumentLine index={1} isDark={isDark} type="subtitle" />
                
                {/* Document Body */}
                <div className="mt-8 space-y-2">
                  {[...Array(6)].map((_, i) => (
                    <DocumentLine key={i} index={i + 2} isDark={isDark} type="text" />
                  ))}
                </div>
  
                {/* Floating AI Suggestions */}
                {[...Array(3)].map((_, i) => (
                  <motion.div
                    key={i}
                    className={`absolute right-8 rounded-lg p-3 max-w-xs
                      ${isDark ? 'bg-blue-500/5 border-blue-500/20' : 'bg-blue-50 border-blue-200'}
                      border shadow-lg`}
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: i * 0.5 + 1 }}
                    style={{ top: `${30 + (i * 25)}%` }}
                  >
                    <div className={`text-xs ${isDark ? 'text-blue-400' : 'text-blue-600'} mb-1`}>
                      AI Suggestion:
                    </div>
                    <div className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
                      {i === 0 ? "Consider adding specific dates" 
                       : i === 1 ? "Include jurisdiction details"
                       : "Add confidentiality terms"}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

export const AnalysisVisual = ({ isDark }) => {
  return (
    <div className="relative w-full h-[700px] perspective-1000">
      <motion.div 
        className={`absolute inset-0 rounded-2xl overflow-hidden
          border transition-colors duration-300
          ${isDark 
            ? 'border-white/10 bg-gray-900/90' 
            : 'border-gray-200 bg-white/90'}`}
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        {/* Split View: Original vs Improved */}
        <div className="flex h-full">
          {/* Original Document Side */}
          <div className="w-1/2 p-6 border-r border-gray-200/20">
            <div className="mb-4">
              <span className={`text-sm font-medium
                ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                Original Document
              </span>
            </div>
            <div className="space-y-4">
              <motion.div 
                className={`p-4 rounded-lg ${
                  isDark ? 'bg-red-500/10' : 'bg-red-100/50'
                }`}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <p className={`text-sm ${
                  isDark ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  This Agreement ("Agreement") is made effective as of date...
                </p>
                <motion.div 
                  className={`mt-2 text-xs ${
                    isDark ? 'text-red-400' : 'text-red-600'
                  }`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.5 }}
                >
                  ↳ Missing specific date reference
                </motion.div>
              </motion.div>

              <motion.div 
                className={`p-4 rounded-lg ${
                  isDark ? 'bg-yellow-500/10' : 'bg-yellow-100/50'
                }`}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <p className={`text-sm ${
                  isDark ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  The parties agree to maintain confidentiality...
                </p>
                <motion.div 
                  className={`mt-2 text-xs ${
                    isDark ? 'text-yellow-400' : 'text-yellow-600'
                  }`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.7 }}
                >
                  ↳ Consider adding specific confidentiality period
                </motion.div>
              </motion.div>
            </div>
          </div>

          {/* Improved Document Side */}
          <div className="w-1/2 p-6">
            <div className="mb-4">
              <span className={`text-sm font-medium
                ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                AI Improvements
              </span>
            </div>
            <div className="space-y-4">
              <motion.div 
                className={`p-4 rounded-lg ${
                  isDark ? 'bg-green-500/10' : 'bg-green-100/50'
                }`}
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.7 }}
              >
                <p className={`text-sm ${
                  isDark ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  This Agreement ("Agreement") is made effective as of January 1, 2024...
                </p>
                <motion.div 
                  className={`mt-2 text-xs ${
                    isDark ? 'text-green-400' : 'text-green-600'
                  }`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.9 }}
                >
                  ✓ Added specific date reference
                </motion.div>
              </motion.div>

              <motion.div 
                className={`p-4 rounded-lg ${
                  isDark ? 'bg-green-500/10' : 'bg-green-100/50'
                }`}
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.9 }}
              >
                <p className={`text-sm ${
                  isDark ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  The parties agree to maintain confidentiality for a period of five (5) years...
                </p>
                <motion.div 
                  className={`mt-2 text-xs ${
                    isDark ? 'text-green-400' : 'text-green-600'
                  }`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 1.1 }}
                >
                  ✓ Added specific confidentiality period
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>

        {/* AI Analysis Indicators */}
        <motion.div 
          className="absolute top-4 right-4 flex items-center space-x-2"
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
        >
          <span className={`px-3 py-1 rounded-full text-xs
            ${isDark 
              ? 'bg-blue-500/20 text-blue-400' 
              : 'bg-blue-100 text-blue-600'}`}>
            AI Analyzing...
          </span>
          <motion.div 
            className="w-2 h-2 rounded-full bg-blue-500"
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export const ComplianceVisual = ({ isDark }) => {
  return (
    <div className="relative w-full h-[700px] perspective-1000">
      <motion.div 
        className={`absolute inset-0 rounded-2xl overflow-hidden
          border transition-colors duration-300
          ${isDark 
            ? 'border-white/10 bg-gray-900/90' 
            : 'border-gray-200 bg-white/90'}`}
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <div className="p-8 h-full">
          {/* Compliance Dashboard */}
          <div className="space-y-6">
            <motion.div 
              className="flex items-center justify-between"
              initial={{ opacity: 0, y: -10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <h3 className={`text-lg font-medium
                ${isDark ? 'text-white' : 'text-gray-900'}`}>
                Compliance Check Results
              </h3>
              <span className={`px-3 py-1 rounded-full text-sm
                ${isDark 
                  ? 'bg-green-500/20 text-green-400' 
                  : 'bg-green-100 text-green-600'}`}>
                All Checks Passed
              </span>
            </motion.div>

            {/* Compliance Categories */}
            {[
              { 
                title: 'GDPR Compliance', 
                status: 'Compliant',
                details: 'Data processing and storage terms verified'
              },
              { 
                title: 'HIPAA Requirements', 
                status: 'Compliant',
                details: 'Protected health information handling confirmed'
              },
              { 
                title: 'Document Structure', 
                status: 'Verified',
                details: 'All required sections present and properly formatted'
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                className={`p-4 rounded-lg border
                  ${isDark 
                    ? 'border-gray-800 bg-gray-800/50' 
                    : 'border-gray-100 bg-gray-50'}`}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <div className="flex justify-between items-start mb-2">
                  <h4 className={`font-medium
                    ${isDark ? 'text-white' : 'text-gray-900'}`}>
                    {item.title}
                  </h4>
                  <motion.span 
                    className={`px-2 py-1 rounded text-xs
                      ${isDark 
                        ? 'bg-green-500/20 text-green-400' 
                        : 'bg-green-100 text-green-600'}`}
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3, delay: index * 0.2 + 0.2 }}
                  >
                    {item.status}
                  </motion.span>
                </div>
                <p className={`text-sm
                  ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  {item.details}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};