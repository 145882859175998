// src/components/FeaturesSection.js
import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

const FeaturesContainer = styled.section`
  padding: 100px 20px;
  background: #f9f9f9;
`;

const FeaturesSection = () => {
  return (
    <Element name="features">
      <FeaturesContainer>
        <h2>Features</h2>
        <p>Feature 1</p>
        <p>Feature 2</p>
        <p>Feature 3</p>
      </FeaturesContainer>
    </Element>
  );
};

export default FeaturesSection;
