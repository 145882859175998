// src/components/features/TransitionSection.jsx
import React from 'react';
import { motion } from 'framer-motion';

const TransitionSection = () => (
  <div className="relative h-32 md:h-48">
    <div className="absolute inset-0 bg-gradient-to-b 
      dark:from-gray-900 dark:to-gray-900/0
      from-gray-50 to-gray-50/0" 
    />
    <motion.div 
      className="max-w-7xl mx-auto px-4 pt-16 text-center"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
    >
      <h2 className="text-4xl md:text-5xl font-bold
        dark:text-gray-200 text-gray-800">
        Powerful Features
      </h2>
      <span className="block text-lg mt-2 font-normal 
        text-gray-700 dark:text-gray-500">
        Experience the future of legal document creation
      </span>
    </motion.div>
  </div>
);

export default TransitionSection;
