// src/components/layout/ThemeToggle.jsx
import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { Sun, Moon } from 'lucide-react';

const ThemeToggle = () => {
    const { isDark, setIsDark } = useTheme();
  
    return (
        <button
            onClick={() => setIsDark(!isDark)}
            className="p-2.5 rounded-lg bg-white/10 dark:bg-gray-800/10
            backdrop-blur-lg border border-gray-200 dark:border-gray-700
            hover:bg-gray-100 dark:hover:bg-gray-700
            transition-all duration-300
            flex items-center justify-center"
        >
        <div className="relative w-6 h-6">
          <Sun className={`absolute inset-0 h-6 w-6 rotate-0 transform 
            ${isDark ? 'text-white' : 'text-yellow-500'} transition-all duration-500
            ${isDark ? 'rotate-180 opacity-0' : 'rotate-0 opacity-100'}`}/>
          <Moon className={`absolute inset-0 h-6 w-6 rotate-0 transform 
            ${isDark ? 'text-white' : 'text-blue-500'} transition-all duration-500
            ${isDark ? 'rotate-0 opacity-100' : '-rotate-180 opacity-0'}`}/>
        </div>
      </button>
    );
  };

export default ThemeToggle;