// src/components/modals/DocumentPreview.jsx
import { motion } from 'framer-motion';

const DocumentPreview = ({ document, onClose, isDark }) => {
    return (
      <motion.div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div 
          className={`w-full max-w-2xl rounded-2xl ${isDark ? 'bg-gray-800' : 'bg-white'} shadow-2xl`}
          initial={{ scale: 0.9, y: 20 }}
          animate={{ scale: 1, y: 0 }}
          exit={{ scale: 0.9, y: 20 }}
        >
          {/* Header */}
          <div className={`flex items-center justify-between p-6 border-b ${isDark ? 'border-gray-700' : 'border-gray-200'}`}>
            <div>
              <h3 className={`text-xl font-bold ${isDark ? 'text-white' : 'text-gray-900'}`}>
                {document.name}
              </h3>
              <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                Preview of the document
              </p>
            </div>
            <button 
              onClick={onClose}
              className={`p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors`}
            >
              ✕
            </button>
          </div>
  
          {/* Content */}
          <div className="p-6 space-y-6">
            {/* Sample content */}
            <div className={`space-y-4 ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
              <p className="text-sm">This {document.name} includes:</p>
              <ul className="list-disc list-inside space-y-2 text-sm">
                <li>Standard legal clauses and terms</li>
                <li>Customizable sections based on your needs</li>
                <li>State-specific compliance measures</li>
                <li>Digital signature support</li>
              </ul>
            </div>
  
            {/* Sample preview */}
            <div className={`p-4 rounded-lg ${isDark ? 'bg-gray-900' : 'bg-gray-50'} text-sm`}>
              <pre className={isDark ? 'text-gray-400' : 'text-gray-600'}>
                {`THIS AGREEMENT is made on [Date]
  BETWEEN:
  [Party A Name], located at [Address]
  AND
  [Party B Name], located at [Address]
  
  1. DEFINITIONS
  ...`}
              </pre>
            </div>
          </div>
  
          {/* Footer */}
          <div className={`p-6 border-t ${isDark ? 'border-gray-700' : 'border-gray-200'} flex justify-end space-x-4`}>
            <button
              onClick={onClose}
              className={`px-4 py-2 rounded-lg
                ${isDark ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}
                transition-colors`}
            >
              Close
            </button>
            <button
              className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors"
            >
              Generate Now
            </button>
          </div>
        </motion.div>
      </motion.div>
    );
  };
  
  export default DocumentPreview;