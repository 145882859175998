// src/components/LandingPage.js
import React from 'react';

const LandingPage = () => {
  return (
    <>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold">Welcome to AequitasAI</h1>
        <p className="mt-4 text-lg">Your tagline here...</p>
      </div>
    </>
  );
};

export default LandingPage;
