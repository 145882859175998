// src/components/features/FloatingGradients.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';

const FloatingGradients = () => {
    const { isDark } = useTheme();
  
    return (
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <motion.div 
        className={`absolute top-0 left-0...`}
        animate={{
          x: [0, 100, 0],
          y: [0, 50, 0],
        }}
        transition={{ duration: 20, repeat: Infinity }}
      />
        {/* Large gradient blob */}
        <div 
          className={`absolute top-0 left-0 w-[1000px] h-[1000px] 
            rounded-full mix-blend-multiply filter blur-[128px] 
            animate-blob
            ${isDark 
              ? 'bg-blue-500 opacity-[0.15]' 
              : 'bg-blue-300 opacity-[0.15]'}`}
        />
        
        {/* Second gradient blob */}
        <div 
          className={`absolute top-1/2 right-0 w-[1000px] h-[1000px] 
            rounded-full mix-blend-multiply filter blur-[128px] 
            animate-blob [animation-delay:4s]
            ${isDark 
              ? 'bg-purple-500 opacity-[0.15]' 
              : 'bg-purple-300 opacity-[0.15]'}`}
        />
        
        {/* Third gradient blob */}
        <div 
          className={`absolute bottom-0 left-1/3 w-[1000px] h-[1000px] 
            rounded-full mix-blend-multiply filter blur-[128px] 
            animate-blob [animation-delay:8s]
            ${isDark 
              ? 'bg-indigo-500 opacity-[0.15]' 
              : 'bg-indigo-300 opacity-[0.15]'}`}
        />
      </div>
    );
  };

export default FloatingGradients;