// src/components/pages/SupportedDocuments.jsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import DocumentPreview from '../modals/DocumentPreview';

const SupportedDocuments = () => {
  const { isDark } = useTheme();
  const [activeCategory, setActiveCategory] = useState('business');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [previewDocument, setPreviewDocument] = useState(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const categories = [
    {
      id: 'business',
      name: 'Business',
      icon: '💼',
      documents: [
        { id: 'nda', name: 'Non-disclosure Agreement', popular: true },
        { id: 'confidentiality', name: 'Confidentiality Agreement' },
        { id: 'operating', name: 'Operating Agreement' },
        { id: 'promissory', name: 'Promissory Note' },
        { id: 'service', name: 'Service Agreement', new: true },
        { id: 'partnership', name: 'Partnership Agreement' }
      ]
    },
    {
      id: 'real-estate',
      name: 'Real Estate',
      icon: '🏠',
      documents: [
        { id: 'lease', name: 'Lease Agreement', popular: true },
        { id: 'rental', name: 'Rental Agreement' },
        { id: 'eviction', name: 'Eviction Notice' },
        { id: 'quitclaim', name: 'Quitclaim Deed' },
        { id: 'billsale', name: 'Bill of Sale', new: true }
      ]
    },
    {
      id: 'family',
      name: 'Family',
      icon: '👨‍👩‍👧‍👦',
      documents: [
        { id: 'power', name: 'Power of Attorney', popular: true },
        { id: 'prenup', name: 'Prenuptial Agreement' },
        { id: 'medical', name: 'Medical Power of Attorney', new: true }
      ]
    },
    {
      id: 'estate',
      name: 'Estate Planning',
      icon: '📋',
      documents: [
        { id: 'will', name: 'Will & Trusts', popular: true },
        { id: 'living-trust', name: 'Living Trust' },
        { id: 'living-will', name: 'Living Will', new: true },
        { id: 'codicil', name: 'Codicil' }
      ]
    }
  ];

  useEffect(() => {
    if (searchTerm) {
      const allDocuments = categories.flatMap(category => 
        category.documents.map(doc => ({
          ...doc,
          category: category.name
        }))
      );
      
      const filtered = allDocuments.filter(doc => 
        doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.category.toLowerCase().includes(searchTerm.toLowerCase())
      );
      
      setFilteredDocuments(filtered);
    } else {
      setFilteredDocuments([]);
    }
  }, [searchTerm]);

  const documentsToShow = searchTerm 
    ? filteredDocuments 
    : categories.find(c => c.id === activeCategory)?.documents || [];

    return (
        <section className={`py-20 ${isDark ? 'bg-gray-900' : 'bg-gray-50'} relative overflow-hidden`}>
          {/* Background Effects */}
          <div className="absolute inset-0 pointer-events-none">
            <motion.div 
              className={`absolute top-0 left-1/4 w-96 h-96 rounded-full 
                ${isDark ? 'bg-blue-500/5' : 'bg-blue-200/20'} blur-3xl`}
              animate={{
                scale: [1, 1.2, 1],
                rotate: [0, 45, 0],
              }}
              transition={{ duration: 20, repeat: Infinity }}
            />
          </div>
    
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
            {/* Section Header */}
            <div className="text-center max-w-3xl mx-auto mb-16">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <h2 className={`text-3xl md:text-4xl font-bold mb-4
                  ${isDark ? 'text-white' : 'text-gray-900'}`}>
                  Supported Documents
                </h2>
                <p className={`text-lg ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  Generate accurate and efficient legal documents in minutes
                </p>
              </motion.div>
            </div>
    
            {/* Search Bar */}
            <div className="max-w-2xl mx-auto mb-12">
            <motion.div 
                className={`relative rounded-2xl transition-all duration-300
                ${isSearchFocused ? 'ring-2 ring-blue-500' : ''}
                ${isDark ? 'bg-gray-800/50' : 'bg-white/50'}`}
            >
                <input
                type="text"
                placeholder="Search documents..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className={`w-full px-6 py-4 rounded-2xl bg-transparent
                    placeholder-gray-400 outline-none
                    ${isDark ? 'text-white' : 'text-gray-900'}`}
                />
                <button
                onClick={() => setSearchTerm('')}
                className={`absolute right-4 top-1/2 -translate-y-1/2 p-2
                    hover:bg-gray-200/10 rounded-full transition-colors
                    flex items-center justify-center
                    ${isDark ? 'text-gray-400' : 'text-gray-600'}`}
                >
                <motion.span
                    animate={{ rotate: searchTerm ? 90 : 0 }}
                    className="block text-xl" // Increased size for better visibility
                >
                    {searchTerm ? '×' : '🔍'}
                </motion.span>
                </button>
            </motion.div>
            </div>
    
            {/* Category Tabs - Only show if not searching */}
            {!searchTerm && (
              <div className="flex flex-wrap justify-center gap-4 mb-12">
                {categories.map((category) => (
                  <motion.button
                    key={category.id}
                    className={`px-6 py-3 rounded-xl flex items-center space-x-2 transition-all
                      ${activeCategory === category.id 
                        ? `${isDark ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-50 text-blue-600'}
                           border-2 border-blue-500/30` 
                        : `${isDark ? 'bg-gray-800/50 text-gray-400' : 'bg-gray-50 text-gray-600'}
                           hover:bg-blue-500/10`
                      }`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setActiveCategory(category.id)}
                  >
                    <span className="text-xl">{category.icon}</span>
                    <span>{category.name}</span>
                  </motion.button>
                ))}
              </div>
            )}
    
            {/* Documents Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {documentsToShow.map((doc, index) => (
                <motion.div
                  key={doc.id}
                  className={`p-6 rounded-xl border backdrop-blur-sm cursor-pointer
                    ${isDark 
                      ? 'bg-gray-800/50 border-gray-700/50' 
                      : 'bg-white/50 border-gray-200/50'}
                    hover:border-blue-500/30 group`}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setPreviewDocument(doc)}
                >
                  <div className="flex justify-between items-start mb-4">
                    <h3 className={`font-medium ${isDark ? 'text-white' : 'text-gray-900'}`}>
                      {doc.name}
                    </h3>
                    <div className="flex space-x-2">
                      {doc.popular && (
                        <span className="px-2 py-1 rounded-full text-xs bg-yellow-500/10 text-yellow-500">
                          Popular
                        </span>
                      )}
                      {doc.new && (
                        <span className="px-2 py-1 rounded-full text-xs bg-green-500/10 text-green-500">
                          New
                        </span>
                      )}
                    </div>
                  </div>
                  
                  <motion.button
                    className={`w-full mt-4 py-2 rounded-lg text-sm
                      group-hover:bg-blue-500 group-hover:text-white transition-all
                      ${isDark ? 'text-gray-400' : 'text-gray-600'}
                      border border-gray-200/20`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Generate Now
                  </motion.button>
                </motion.div>
              ))}
            </div>
    
            {/* View More Button */}
            <motion.div
              className="mt-12 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <button className={`px-8 py-4 rounded-xl 
                ${isDark 
                  ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20' 
                  : 'bg-blue-50 text-blue-600 hover:bg-blue-100'}
                transition-all`}>
                View All Documents
              </button>
            </motion.div>
          </div>
    
          {/* Document Preview Modal */}
          <AnimatePresence>
            {previewDocument && (
              <DocumentPreview 
                document={previewDocument}
                onClose={() => setPreviewDocument(null)}
                isDark={isDark}
              />
            )}
          </AnimatePresence>
        </section>
      );
    };
    
    export default SupportedDocuments;