// src/components/CTASection.js
import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { NavLink } from 'react-router-dom';

const CTASection = () => {
  const { isDark } = useTheme();

  return (
    <section className={`${isDark ? 'bg-gray-900' : 'bg-white'} py-20`}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className={`text-4xl font-extrabold tracking-tight ${isDark ? 'text-white' : 'text-gray-900'}`}>
          Ready to Transform Your Legal Workflow?
        </h2>
        <p className={`mt-4 text-xl ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
          Sign up today and start your free trial to experience the power of AI-assisted legal documentation.
        </p>
        <div className="mt-8 flex justify-center">
          <NavLink to="/">
            <button className="px-8 py-4 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full text-white font-medium hover:from-blue-700 hover:to-indigo-700 transition-all duration-300 transform hover:scale-105 shadow-lg">
              Start Your Free Trial
            </button>
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
