// src/components/FAQ.jsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';

const FAQ = () => {
  const { isDark } = useTheme();
  const [openIndex, setOpenIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const faqs = [
    {
      question: "How does AequitasAI ensure accuracy in legal documents?",
      answer: `Our AI combines advanced machine learning with expert legal knowledge to ensure accuracy. Here's how:
        • Trained on millions of verified legal documents
        • Real-time verification against current legal standards
        • Multi-layer validation process
        • Smart error detection and correction
        
        Each document goes through a comprehensive review process before being finalized.`,
      tags: ['accuracy', 'technology', 'quality']
    },
    {
      question: "How does AequitasAI protect sensitive legal information?",
      answer: `We maintain the highest standards of security and confidentiality:
        • End-to-end encryption for all documents
        • SOC 2 Type II certified infrastructure
        • Zero access to document contents
        • Regular security audits
        • Compliance with GDPR and CCPA
        
        Your data's security is our top priority.`,
      tags: ['security', 'privacy', 'compliance']
    },
    {
      question: "What makes AequitasAI different from traditional legal document services?",
      answer: `AequitasAI stands out through:
        • Advanced AI that understands legal context
        • Real-time updates with changing laws
        • Interactive document generation
        • Smart suggestions and improvements
        • Seamless integration with existing workflows
        
        We're not just automating documents; we're revolutionizing legal document creation.`,
      tags: ['features', 'comparison']
    },
    {
      question: "Can AequitasAI handle complex legal requirements?",
      answer: `Absolutely. Our AI is designed to handle complexity:
        • Multi-jurisdictional compliance
        • Industry-specific requirements
        • Custom clause integration
        • Complex dependency handling
        • Automated cross-referencing
        
        The more complex the requirement, the more valuable our AI becomes.`,
      tags: ['capabilities', 'features']
    },
    {
      question: "How quickly can I generate a legal document?",
      answer: `Document generation is remarkably fast:
        • Basic documents: 2-5 minutes
        • Complex agreements: 5-15 minutes
        • Custom documents: 15-30 minutes
        
        Most importantly, the quality is never compromised for speed.`,
      tags: ['speed', 'efficiency']
    }
  ];

  const filteredFaqs = faqs.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.tags.some(tag => tag.includes(searchTerm.toLowerCase()))
  );

  return (
    <section className={`py-20 ${isDark ? 'bg-gray-900' : 'bg-gray-50'} relative overflow-hidden`}>
      {/* Background Effects */}
      <div className="absolute inset-0 pointer-events-none">
        <motion.div 
          className={`absolute bottom-0 right-1/4 w-96 h-96 rounded-full 
            ${isDark ? 'bg-blue-500/5' : 'bg-blue-200/20'} blur-3xl`}
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, 45, 0],
          }}
          transition={{ duration: 20, repeat: Infinity }}
        />
      </div>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h2 className={`text-3xl md:text-4xl font-bold mb-4
              ${isDark ? 'text-white' : 'text-gray-900'}`}>
              Frequently Asked Questions
            </h2>
            <p className={`text-lg ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Everything you need to know about AequitasAI
            </p>
          </motion.div>
        </div>

        {/* Search Bar */}
        <div className="mb-12">
          <div className={`relative rounded-xl transition-all duration-300
            ${isDark ? 'bg-gray-800/50' : 'bg-white/50'}`}>
            <input
              type="text"
              placeholder="Search questions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`w-full px-6 py-4 rounded-xl bg-transparent
                placeholder-gray-400 outline-none
                ${isDark ? 'text-white' : 'text-gray-900'}`}
            />
            <button
              onClick={() => setSearchTerm('')}
              className="absolute right-4 top-1/2 -translate-y-1/2"
            >
              {searchTerm ? '✕' : '🔍'}
            </button>
          </div>
        </div>

        {/* FAQ List */}
        <div className="space-y-4">
          {filteredFaqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <button
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className={`w-full text-left p-6 rounded-xl transition-all
                  ${isDark 
                    ? 'bg-gray-800/50 hover:bg-gray-800' 
                    : 'bg-white/50 hover:bg-white'}
                  ${openIndex === index ? 'shadow-lg' : ''}`}
              >
                <div className="flex justify-between items-center">
                  <h3 className={`font-medium ${isDark ? 'text-white' : 'text-gray-900'}`}>
                    {faq.question}
                  </h3>
                  <motion.span
                    animate={{ rotate: openIndex === index ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    ↓
                  </motion.span>
                </div>

                <AnimatePresence>
                  {openIndex === index && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                      <p className={`mt-4 whitespace-pre-line
                        ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                        {faq.answer}
                      </p>
                      <div className="mt-4 flex flex-wrap gap-2">
                        {faq.tags.map(tag => (
                          <span
                            key={tag}
                            className={`text-xs px-2 py-1 rounded-full
                              ${isDark 
                                ? 'bg-blue-500/10 text-blue-400' 
                                : 'bg-blue-50 text-blue-600'}`}
                          >
                            #{tag}
                          </span>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;