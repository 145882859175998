// src/components/pages/HowItWorks.jsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';

const HowItWorks = () => {
  const { isDark } = useTheme();
  const [selectedDocument, setSelectedDocument] = useState('nda');
  const [selectedType, setSelectedType] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const documents = [
    { id: 'nda', name: 'Non-Disclosure Agreement', icon: '🤝' },
    { id: 'employment', name: 'Employment Contract', icon: '👔' },
    { id: 'service', name: 'Service Agreement', icon: '📝' }
  ];

  const ndaTypes = [
    { id: 'one-way', name: 'One-Way (Unilateral)', description: 'One party discloses information' },
    { id: 'mutual', name: 'Two-Way (Mutual)', description: 'Both parties share information' }
  ];

  // Simulate document generation
  const handleGenerate = () => {
    setIsGenerating(true);
    setTimeout(() => {
      setIsGenerating(false);
    }, 3000);
  };

  const steps = [
    {
      id: 1,
      title: "Select Document Type",
      description: "Choose from our extensive library of legal documents, from NDAs to contracts.",
      icon: "📑",
      animation: (
        <motion.div 
          className={`rounded-xl p-6 ${isDark ? 'bg-gray-800/50' : 'bg-white/50'} 
            backdrop-blur-sm border border-gray-200/20`}
        >
          <div className="space-y-3">
            {documents.map((doc) => (
              <motion.div 
                key={doc.id}
                className={`p-4 rounded-lg cursor-pointer transition-all
                  ${selectedDocument === doc.id 
                    ? `${isDark ? 'bg-blue-500/20' : 'bg-blue-50'} 
                       border-2 border-blue-500/30` 
                    : `${isDark ? 'bg-gray-800/50' : 'bg-gray-50'}
                       hover:bg-blue-500/10`
                  }`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setSelectedDocument(doc.id)}
              >
                <div className="flex items-center space-x-3">
                  <span className="text-2xl">{doc.icon}</span>
                  <span className={isDark ? 'text-white' : 'text-gray-900'}>
                    {doc.name}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      )
    },
    {
      id: 2,
      title: "Answer Key Questions",
      description: "Our AI guides you through essential questions to customize your document.",
      icon: "💡",
      animation: (
        <motion.div 
          className={`rounded-xl p-6 ${isDark ? 'bg-gray-800/50' : 'bg-white/50'} 
            backdrop-blur-sm border border-gray-200/20`}
        >
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className={`text-sm ${isDark ? 'text-blue-400' : 'text-blue-600'}`}>
                Question 2 of 5
              </div>
              <motion.div 
                className="h-1 w-20 bg-gray-200 rounded-full overflow-hidden"
              >
                <motion.div 
                  className="h-full bg-blue-500"
                  initial={{ width: "20%" }}
                  whileInView={{ width: "40%" }}
                  transition={{ duration: 0.8 }}
                />
              </motion.div>
            </div>

            <div className={`text-lg font-medium ${isDark ? 'text-white' : 'text-gray-900'}`}>
              What type of NDA do you need?
            </div>

            <div className="space-y-3">
              {ndaTypes.map((type) => (
                <motion.button 
                  key={type.id}
                  className={`w-full p-4 rounded-lg text-left transition-all
                    ${selectedType === type.id 
                      ? `${isDark ? 'bg-blue-500/20' : 'bg-blue-50'} 
                         border-2 border-blue-500/30` 
                      : `${isDark ? 'bg-gray-800/50' : 'bg-gray-50'}
                         hover:bg-blue-500/10`
                    }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setSelectedType(type.id)}
                >
                  <div className="space-y-1">
                    <div className={isDark ? 'text-white' : 'text-gray-900'}>
                      {type.name}
                    </div>
                    <div className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                      {type.description}
                    </div>
                  </div>
                </motion.button>
              ))}
            </div>
          </div>
        </motion.div>
      )
    },
    {
        id: 3,
        title: "Review & Generate",
        description: "AI instantly generates your document with smart suggestions and improvements.",
        icon: "✨",
        animation: (
          <motion.div 
            className={`rounded-xl p-6 ${isDark ? 'bg-gray-800/50' : 'bg-white/50'} 
              backdrop-blur-sm border border-gray-200/20`}
          >
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div className={`text-sm ${isDark ? 'text-green-400' : 'text-green-600'}`}>
                  {isGenerating ? 'Generating document...' : 'Ready to generate'}
                </div>
                {isGenerating && (
                  <motion.div 
                    className="w-2 h-2 rounded-full bg-green-500"
                    animate={{ scale: [1, 1.2, 1] }}
                    transition={{ duration: 1, repeat: Infinity }}
                  />
                )}
              </div>
  
              {/* Generation Progress Bars */}
              <div className="space-y-4">
                <div className="space-y-2">
                  <div className={`text-xs ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                    Analyzing requirements...
                  </div>
                  <motion.div 
                    className="h-1 rounded-full bg-gray-200 overflow-hidden"
                    initial={{ width: "100%" }}
                  >
                    <motion.div 
                      className="h-full bg-blue-500"
                      initial={{ width: "0%" }}
                      animate={{ width: isGenerating ? "100%" : "0%" }}
                      transition={{ duration: 1.5 }}
                    />
                  </motion.div>
                </div>
  
                <div className="space-y-2">
                  <div className={`text-xs ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                    Generating content...
                  </div>
                  <motion.div 
                    className="h-1 rounded-full bg-gray-200 overflow-hidden"
                    initial={{ width: "100%" }}
                  >
                    <motion.div 
                      className="h-full bg-green-500"
                      initial={{ width: "0%" }}
                      animate={{ width: isGenerating ? "80%" : "0%" }}
                      transition={{ duration: 1.5, delay: 0.5 }}
                    />
                  </motion.div>
                </div>
  
                <div className="space-y-2">
                  <div className={`text-xs ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                    Formatting document...
                  </div>
                  <motion.div 
                    className="h-1 rounded-full bg-gray-200 overflow-hidden"
                    initial={{ width: "100%" }}
                  >
                    <motion.div 
                      className="h-full bg-purple-500"
                      initial={{ width: "0%" }}
                      animate={{ width: isGenerating ? "60%" : "0%" }}
                      transition={{ duration: 1.5, delay: 1 }}
                    />
                  </motion.div>
                </div>
              </div>
  
              {/* Generate Button */}
              <motion.button
                className={`w-full p-4 rounded-lg text-white
                  ${isGenerating 
                    ? 'bg-gray-500 cursor-not-allowed' 
                    : 'bg-blue-500 hover:bg-blue-600'}`}
                whileHover={!isGenerating ? { scale: 1.02 } : {}}
                whileTap={!isGenerating ? { scale: 0.98 } : {}}
                onClick={handleGenerate}
                disabled={isGenerating}
              >
                {isGenerating ? 'Generating...' : 'Generate Document'}
              </motion.button>
            </div>
          </motion.div>
        )
      },
      {
        id: 4,
        title: "Download & Use",
        description: "Get your professionally formatted document in DOC, PDF, or other formats.",
        icon: "📥",
        animation: (
          <motion.div 
            className={`rounded-xl p-6 ${isDark ? 'bg-gray-800/50' : 'bg-white/50'} 
              backdrop-blur-sm border border-gray-200/20`}
          >
            <div className="space-y-4">
              <motion.button 
                className={`w-full p-4 rounded-lg flex items-center justify-center space-x-2
                  ${isDark ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-50 text-blue-600'}
                  border-2 border-blue-500/30`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <span>Download as PDF</span>
                <span>↓</span>
              </motion.button>
  
              <div className="grid grid-cols-2 gap-3">
                {['DOCX', 'TXT', 'RTF', 'HTML'].map((format) => (
                  <motion.button 
                    key={format}
                    className={`p-3 rounded-lg
                      ${isDark ? 'bg-gray-800/50 text-gray-300' : 'bg-gray-50 text-gray-600'}
                      hover:bg-blue-500/10`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    {format}
                  </motion.button>
                ))}
              </div>
            </div>
          </motion.div>
        )
      }
    ];
  
    return (
      <section className={`py-20 ${isDark ? 'bg-gray-900' : 'bg-gray-50'} relative overflow-hidden`}>
        {/* Background Effects */}
        <div className="absolute inset-0 pointer-events-none">
          <motion.div 
            className={`absolute top-20 -right-20 w-96 h-96 rounded-full 
              ${isDark ? 'bg-blue-500/5' : 'bg-blue-200/20'} blur-3xl`}
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 90, 0],
            }}
            transition={{ duration: 20, repeat: Infinity }}
          />
          <motion.div 
            className={`absolute bottom-20 -left-20 w-96 h-96 rounded-full 
              ${isDark ? 'bg-purple-500/5' : 'bg-purple-200/20'} blur-3xl`}
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, -90, 0],
            }}
            transition={{ duration: 20, repeat: Infinity, delay: 10 }}
          />
        </div>
  
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          {/* Section Header */}
          <div className="text-center max-w-3xl mx-auto mb-16">
            <motion.h2 
              className={`text-3xl md:text-4xl font-bold mb-4
                ${isDark ? 'text-white' : 'text-gray-900'}`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              How It Works
            </motion.h2>
            <motion.p 
              className={`text-lg ${isDark ? 'text-gray-400' : 'text-gray-600'}`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
            >
              Generate professional legal documents in minutes, not hours
            </motion.p>
          </div>
  
          {/* Steps Grid */}
          <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
            {steps.map((step, index) => (
              <motion.div
                key={step.id}
                className="relative"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                {/* Step Content */}
                <div className="mb-6">
                  <div className="flex items-center space-x-3 mb-2">
                    <span className={`text-2xl ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                      {step.icon}
                    </span>
                    <h3 className={`text-xl font-semibold 
                      ${isDark ? 'text-white' : 'text-gray-900'}`}>
                      {step.title}
                    </h3>
                  </div>
                  <p className={`text-base ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                    {step.description}
                  </p>
                </div>
  
                {/* Step Animation */}
                {step.animation}
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default HowItWorks;