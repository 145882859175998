// src/components/features/FeaturesSection.js
import React, { useRef } from 'react';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import TransitionSection from './TransitionSection';
import FloatingGradients from './FloatingGradients';
import { DocumentVisual, AnalysisVisual, ComplianceVisual } from './FeatureVisuals';
import ParticleSystem from './ParticleSystem';
import InteractiveBackground from './InteractiveBackground';

const features = [
  {
    id: 1,
    title: "Document Generation",
    tagline: "AI-Powered Speed",
    description: "Create precise legal documents in minutes, not hours",
    color: "from-blue-500 to-indigo-500",
    lightColor: "from-blue-400 to-indigo-400",
    Visual: DocumentVisual
  },
  {
    id: 2,
    title: "Smart Analysis",
    tagline: "Real-time Intelligence",
    description: "Watch AI analyze and improve your documents instantly",
    color: "from-violet-500 to-purple-500",
    lightColor: "from-violet-400 to-purple-400",
    Visual: AnalysisVisual
  },
  {
    id: 3,
    title: "Compliance Check",
    tagline: "Always Compliant",
    description: "Automatic verification across jurisdictions",
    color: "from-emerald-500 to-teal-500",
    lightColor: "from-emerald-400 to-teal-400",
    Visual: ComplianceVisual
  }
];

const FeaturesSection = () => {
  const { isDark } = useTheme();
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  return (
    <section 
      ref={containerRef}
      className="relative transition-colors duration-300
        dark:bg-gray-900 bg-gray-50 overflow-hidden"
    >
      {/* <InteractiveBackground /> */}
      <FloatingGradients />
      <ParticleSystem />
      <TransitionSection />

      {features.map((feature, index) => (
        <FeatureBlock 
          key={feature.id}
          feature={feature}
          index={index}
          progress={scrollYProgress}
          isDark={isDark}
        />
      ))}
    </section>
  );
};

const FeatureBlock = ({ feature, index, progress, isDark }) => {
  return (
    <div className="min-h-screen flex items-center justify-center py-20">
      <div className="max-w-7xl mx-auto px-4 w-full space-y-16">
        <motion.div 
          className="text-center space-y-6"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ 
            once: true, 
            amount: 0.5 // Changed from margin to amount
          }}
          transition={{ duration: 0.8 }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ 
              duration: 0.5,
              ease: "easeOut" // Changed from spring to easeOut
            }}
          >
            <span className={`inline-block text-sm px-4 py-1.5 rounded-full 
              bg-gradient-to-r ${isDark ? feature.color : feature.lightColor}
              bg-opacity-10 text-gray-900 dark:text-white font-medium`}
            >
              {feature.tagline}
            </span>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ 
              duration: 0.5,
              delay: 0.1,
              ease: "easeOut"
            }}
          >
            <h2 className="text-5xl md:text-7xl font-bold">
              <span className={`bg-gradient-to-r ${isDark ? feature.color : feature.lightColor}
                bg-clip-text text-transparent`}>
                {feature.title}
              </span>
            </h2>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ 
              duration: 0.5,
              delay: 0.2,
              ease: "easeOut"
            }}
          >
            <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
              {feature.description}
            </p>
          </motion.div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{ 
            duration: 0.8,
            delay: 0.3,
            ease: "easeOut"
          }}
        >
          <feature.Visual isDark={isDark} />
        </motion.div>
      </div>
    </div>
  );
};

export default FeaturesSection;