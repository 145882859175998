// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFd8yZQu7aWJeMKNHAp415P80N1K0wNjY",
  authDomain: "aequitasai.firebaseapp.com",
  projectId: "aequitasai",
  storageBucket: "aequitasai.appspot.com",
  messagingSenderId: "862865793605",
  appId: "1:862865793605:web:d64e2f5a0352594e22f92c",
  measurementId: "G-VBL8RQBDPV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider };